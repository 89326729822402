export default ({ mainCheckbox, container }) => {
  const controlledCheckboxes = () => container.querySelectorAll('[data-controllable]')

  const check = (isChecked) => {
    controlledCheckboxes().forEach((checkbox) => {
      checkbox.checked = isChecked
    })
  }

  mainCheckbox.addEventListener('click', () => {
    check(mainCheckbox.checked)
  }, false)

  return {
    check
  }
}
